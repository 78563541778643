:root {
  --base-color: #fdfdfd;
  --base-color-dark: #e0e0e0;
  --secondary-color: #006245;
  --complimentary-color: #13426E;
  --error-color: #e35858;
  --dark: #2f2f2f;
  --max-width: 675px;
}

html, body {
  color: var(--secondary-color);
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-optical-sizing: auto;
  font-variation-settings: "slnt" 0;
  margin: 0;
  padding: 0;
}

.h1 {
  font-size: 48px;
  font-weight: 700;
}

.h2 {
  font-size: 24px;
  font-weight: 700;
}

.h3 {
  font-size: 16px;
  font-weight: 700;
}

.h4 {
  font-size: 12px;
  font-weight: 700;
}

.h5 {
  font-size: 10px;
  font-weight: 700;
}

.h6 {
  font-size: 8px;
  font-weight: 700;
}

p {
  font-size: 16px;
  line-height: 1.1;
}

a {
  color: var(--complimentary-color);
}

.small {
  color: #888;
  display: block;
  font-size: 11px;
}

body {
  background-color: var(--base-color-dark);
}

header,
main,
footer {
  box-sizing: unset;
  padding: 22px;
  width: 100%;
  max-width: var(--max-width);
}

@media (max-width:641px) {
  footer {
    text-align: center;
  }
  .form-basic {
    padding-left: 1.2rem;
    padding-right: 1rem;
  }
}

header h1 {
  text-align: center;
}

main {
  flex: 1 1;
}

section {
  max-width: var(--max-width);
  width: 100%;
}

section.description p {
  line-height: 1.3;
  margin: 0;
}

@media (max-width:641px) {
  section.description p {
    padding: 0 0.8rem 0 1.2rem;
  }
}

.logo {
  max-width: 280px;
}

.disclaimer {
  display: block;
  font-size: 14px;
  font-style: italic;
  margin-bottom: 22px;
}

.copyright {
  display: block;
  font-size: 12px;
}

.flex {
  display: flex;
  flex-direction: inherit;
  justify-content: flex-start;
}
.flex.justify-center {
  justify-content: center;
}

.row {
  flex-direction: row;
}
.row-item--content {
  padding: 22px;
}

.column {
  flex-direction: column;
}
.column.align-center {
  align-items: center;
}

.full-height {
  height: 100vh;
}

.full-width {
  width: 100%;
}

.bg-base {
  background-color: var(--base-color-dark);
}

.bg-secondary {
  background-color: var(--secondary-color);
}

.base {
  color: var(--base-color);
}

.secondary {
  color: var(--secondary-color);
}

.complimentary {
  color: var(--complimentary-color);
}

.dark {
  color: var(--dark);
}

.padding-extra-44 {
  padding-bottom: 44px;
  padding-top: 44px;
}

.form-basic {
  max-width: 675px;
}
.form-basic h2 {
  font-family: "Futura", sans-serif;
  font-size: 36px;
  font-weight: 300;
}
.form-basic h3 {
  font-family: "Futura", sans-serif;
  font-size: 24px;
  font-weight: 300;
}

@media (max-width:641px) {
  .form-basic {
    padding-left: 1.2rem;
  }
}

.form-security {
  display: none;
}

label {
  display: block;
  font-family: "Georgia", serif;
  font-weight: 300;
}
label + input, label + textarea, label + select, label + .input-wrap {
  margin-bottom: 16px;
  margin-top: 8px;
}

input, textarea {
  border: none;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
  padding: 8px;
  width: 100%;
}

.btn {
  border: none;
  cursor: pointer;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 800;
  letter-spacing: 0.15rem;
  text-transform: uppercase;
}
.btn.pill {
  border-radius: 11px;
  padding: 11px 22px;
  width: auto;
}
.btn:hover {
  filter: brightness(140%);
}

.submit-btn-container {
  align-items: center;
  display: flex;
  gap: 20px;
}

.loader {
  display: block;
  width: 20px;
  height: 20px;
}

.pre .loader {
  display: none;
}

.submitting .loader {
  animation: spin-anim 1.2s linear infinite;
  background-image: url('./assets/loading.svg');
  background-position: center;
  background-repeat: no-repeat;
}

@keyframes spin-anim {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}

.form-group.error input, .form-group.error textarea, .form-group.error select {
  border-bottom: 1px solid var(--error-color);
}

.grid {
  display: grid;
  gap: 8px;
}
.grid.grid-4-columns {
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.grid.grid-4-columns .item-a {
  grid-row: 1;
  grid-column: 1/span 4;
}
.grid.grid-4-columns .item-b {
  grid-row: 2;
  grid-column: 1/span 4;
}
.grid.grid-4-columns .item-c {
  grid-row: 3;
  grid-column: 1/span 2;
}
.grid.grid-4-columns .item-d {
  grid-row: 3;
  grid-column: 1;
  grid-column-start: 3;
}
.grid.grid-4-columns .item-e {
  grid-row: 3;
  grid-column: 1;
  grid-column-start: 4;
}
.grid.grid-4-columns .col-2 {
  grid-column: 1/span 2;
}
.grid.grid-4-columns .start-3 {
  grid-column-start: 3;
}
.grid.grid-4-columns .col-4 {
  grid-column: 1/span 4;
}
.grid.grid-4-columns .row-1 {
  grid-row: 1;
}
.grid.grid-4-columns .row-2 {
  grid-row: 2;
}
.grid.grid-4-columns .row-3 {
  grid-row: 3;
}

@media (max-width:641px) {
  .grid {
    display: flex;
    flex-direction: column;
  }
}

textarea {
  min-height: 80px;
}

select {
  border: none;
  padding: 8px;
}

.error-msg {
  color: var(--error-color);
  display: block;
  font-family: "Georgia", serif;
  font-size: 14px;
  font-style: italic;
  margin-bottom: 22px;
}

.form-submit-message {
  padding: 22px 0;
}

.msg {
  margin-bottom: 22px;
}

.input-wrap {
  display: flex;
  position: relative;
}

.input-icon .input-wrap input {
  align-self: flex-end;
  width: 95%;
}

.input-icon .input-wrap::before {
  background-color: var(--secondary-color);
  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: center;
  
  color: white;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 800;
  line-height: 0;
  
  width: 30px;
  height: auto;
}

.input-icon.money .input-wrap::before {
  content: '$';
}

.input-icon.email .input-wrap::before {
  content: '@';
}

section.description {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}


section.description p.warning-msg {
  align-self: center;
  margin: 0;
  background-color: hsl(0deg 72.36% 81.75%);
  border-radius: 0.4rem;
  color: hsl(0deg 100% 40.84%);
  display: block;
  font-size: 12px;
  font-weight: 700;
  left: -19px;
  line-height: 1;
  padding: 0.5rem;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  width: 190px;
}